@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  --main-bg-color: #101011;
  --secondary:  #1a1a1b;
}

.app {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .navbar{
    height: 70px;
    width: 100%;
    background-color: var(--main-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    img{
      width: 50%;
    }
  }
  .cont{
    padding-inline: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .top{
      border-radius: 15px;
      height: 460px;
      width: 100%;
      overflow: hidden;
      background-color: var(--main-bg-color);
      .ta{
        padding: 10px;
        padding-inline: 15px;
        display: flex;
        justify-content: space-between;
        min-height: 30px;
        display: flex;
        align-items: center;
        .taa{
          margin-right: -20px;
        }
        input{
          width: 175px;
          height: 30px;
          border-radius: 30px;
          border: none;
          padding-inline: 10px;
          text-align: center;
          outline: none;
          background-color: var(--secondary);
          color: white;
        }
        input:focus{
          text-align: left;
          placeholder{
            color: transparent;
          }
        }
        input:focus input::-webkit-input-placeholder {
          opacity: 0;
        }
        .searchIc{
          position: relative;
          right: 30px;
          top: 6px;
 
          height: 2%;
        }
        .tsearch{
          padding: 10px;
          padding-bottom: 15px;
          right: 30px;
          position: absolute;
          background-color:#2625259a;
          overflow: hidden;
          width: 170px;
          border-radius: 10px;
          text-align: center;
          backdrop-filter: blur(10px);
          z-index: 999;
        }
        .tab{
          display: none;
        }
      }
      .tb{
        margin-right: 10px;
        padding-top: 6vh;
        align-items: center;
        display: flex;
        flex-direction: column;
        .avg{
          font-size: 92px;
          color: #00D1FF;
          font-weight: 500;
        }
        .tc{
          margin-left: -50px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          .tp{
            display: flex;
            align-items: center;
            gap: 10px;

          }
          
        }
        .tcc{
          .hourly{
            
            display: none;
          }
        }
        .condition{
          opacity: 10%;
          right: 160px;
          margin-right: -12vh;
          img{
            width: 280%;
            margin-top: -30px;
          }
        }
      }
    }
    .bottom{
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 20px;
      row-gap: 20px;
      .day{
        border-radius: 15px;
        height: 260px;
        background-color: var(--main-bg-color);
        .d-top{
          padding: 10px;
          .ta{
            
          }
          .t-b{
            opacity: 50%;
          }
        }
        .d-bottom{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
          .mid{
            color: #00D1FF;
            margin-top: 40px;
            font-size: 32px;
            margin-bottom: 10px;
          }
          img{
            width: 23%;
            opacity: 30%;
          }
        }
      }
    }
  }
  .footer{
    width: 100%;
    background-color: var(--main-bg-color);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
}
@media (min-width: 768px) {
  .app{
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar{
      padding-top: 5px;
      img{
        width: 25vh;
      }
    }
    .cont{
      height: 82.8vh;
      width: 80vw;
      .top{
        .ta{
          input{
            width: 300px;
          }
          .tab{
            display: block;
          }
          .tsearch{
            right: initial;
            width: 300px;
          }
        }
        .tb{
          
          height: 200px;
          display: flex;
          padding-left: 5vw;
          gap: 5vw;
          flex-direction: row;
          align-items: center;
          .avg{
            margin-right: 30px;
          }
          .tcc{
            .tc{
              display: flex;
              flex-direction: row;
              gap: 190px;
              margin-bottom: 50px;
              .tp{
                width: 144px;
              }
            }
            .hourly{
              margin-left: -50px;
              display: flex;
              flex-direction: row;
              overflow: hidden;
              gap: 57px;
              .hourlyunit{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                font-size: 18px;
                .temp{
                  font-size: 20px;
                    color: #00D1FF;
                }
              }
            }
          }
          .condition{
            margin-left:-80px;
            img{
              width: 300px;
              margin-bottom: -80px;
            }
          }
        }
      }
      .bottom{
        display: flex;       
        .day{
          width: 100%;
          height: 340px;
          .d-bottom{
            .mid{
              margin-top: 70px;
              font-size: 45px;
            }
            gap: 80px;
          }
        }
      }
    }
  }
}